import React, { useContext, useState } from 'react';
import { Checkbox, Typography, Link, CircularProgress } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import { TermsCardWrapper } from './style';
import { ITermsCardProps } from '../../interfaces';
import { useTermsAgreementCard } from '../../useTermsAgreementCard';
import { DSW_COMPANY_ID } from '../../../../../Utils/constants';
import CheckoutFrames from '../../../CheckoutFrames/CheckoutFrames';
import { calculateServiceFees } from '../../../../../Utils/format';
// import ArrowForwardIcon from '@mui/icons-material/KeyboardArrowRight';

const TermsAgreementCardTheme2: React.FC<ITermsCardProps> = ({
  onClick,
  isLoading,
  packageValidationOutput,
  totalPrice,
  error,
  disabled,
  hasServiceFees = false,
}) => {
  const { userAgreed, company, checkBoxHandler, getPaymentText } = useTermsAgreementCard(
    totalPrice!,
    packageValidationOutput!
  );

  const renderAgreementText = () => {
    const links = {
      privacy: (
        <Link
          key="privacy"
          underline="always"
          component={ReactLink}
          to={`${company?.footerPagesLinks?.privacy}`}
          target="_blank"
          onClick={e => {
            if (!company?.footerPagesLinks?.privacy) {
              e.preventDefault();
            }
          }}
        >
          privacy policy
        </Link>
      ),
      terms: (
        <Link
          key="terms"
          underline="always"
          component={ReactLink}
          to={`${company?.footerPagesLinks?.terms}`}
          target="_blank"
          onClick={e => {
            if (!company?.footerPagesLinks?.terms) {
              e.preventDefault();
            }
          }}
        >
          terms & conditions
        </Link>
      ),
      cancellation: company?.cancellation_policy && company?.cancellation_policy > 0 && (
        <Link
          key="cancellation"
          component={ReactLink}
          to={`${company?.footerPagesLinks?.refund}`}
          target="_blank"
          underline="always"
          onClick={e => {
            if (!company?.footerPagesLinks?.refund) {
              e.preventDefault();
            }
          }}
        >
          cancellation policy
        </Link>
      ),
    };

    return (
      <>
        I agree to the {links.privacy}, {links.terms}
        {company?.cancellation_policy && company?.cancellation_policy > 0 && (
          <>, {links.cancellation}</>
        )}
        {company?.cancellation_policy && company?.cancellation_policy > 0 && (
          <>
            {' '}
            (Bookings can be cancelled online only {company?.cancellation_policy} hours prior to the
            booking date)
          </>
        )}
      </>
    );
  };

  return (
    <TermsCardWrapper>
      <div className="form-control-label">
        <div className="checkbox-container">
          <Checkbox
            checked={userAgreed}
            onChange={checkBoxHandler}
            id="terms-checkbox"
            data-testid="terms-checkbox"
          />
        </div>
        <Typography className="label-content">{renderAgreementText()}</Typography>
      </div>

      {company?.id === DSW_COMPANY_ID && totalPrice !== undefined && totalPrice > 0 ? (
        <CheckoutFrames handleCheckout={onClick} totalPrice={totalPrice} userAgreed={userAgreed} />
      ) : (
        <button
          className="payment-button"
          disabled={!userAgreed || isLoading || disabled}
          onClick={() => onClick()}
        >
          <div className="button-content">
            {isLoading ? (
              <CircularProgress size={24} className="loading-spinner" sx={{ color: '#ffffff' }} />
            ) : (
              <>
                <span className="button-text">
                  {getPaymentText()}
                  {totalPrice !== undefined && totalPrice > 0
                    ? ` - ${totalPrice + (hasServiceFees ? calculateServiceFees(totalPrice, company) : 0)} ${company?.currency || 'AED'}`
                    : ''}
                </span>
                {/* <span className="icon-container">
                  <ArrowForwardIcon />
                </span> */}
              </>
            )}
          </div>
        </button>
      )}

      {error && <Typography className="error-message">{error}</Typography>}
    </TermsCardWrapper>
  );
};

export default TermsAgreementCardTheme2;
