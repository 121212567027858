import React, { useState } from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import TermsAgreementCard from './TermsAgreementCard';
import { isValidEmail } from '../../Utils/validate';
import { UserInformationWrapper } from '../shared/userInformationForm/style';
import CustomizedTextField from '../FormCollection/TextField';
import PhoneNumberField from '../FormCollection/phoneNumberField';
import { formatPhoneNumber } from '../../Utils/format';
import { ValidationOutput } from '../../hooks/usePackage';

export interface IUserForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
}

interface IProps {
  handleClick: any;
  isLoading: boolean;
  packageValidationOutput?: ValidationOutput;
}

const GuestModeForm: React.FC<IProps> = ({ handleClick, isLoading, packageValidationOutput }) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [country, setCountry] = useState('AE');

  const isDisabled = () => {
    return (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      !isValidEmail(email)
    );
  };

  return (
    <Stack spacing={3}>
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            mb: 1,
            color: 'text.primary'
          }}
        >
          Checkout as a Guest
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ mb: 3 }}
        >
          Please fill in your details to complete the booking
        </Typography>

        <Stack spacing={3}>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
            gap: 2 
          }}>
            <CustomizedTextField
              id="first_name"
              label="First Name"
              value={firstName}
              setValue={setFirstName}
              isRequired={true}
              placeholder="Enter your first name"
            />

            <CustomizedTextField
              id="last_name"
              label="Last Name"
              value={lastName}
              setValue={setLastName}
              isRequired={true}
              placeholder="Enter your last name"
            />
          </Box>

          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
            gap: 2 
          }}>
            <CustomizedTextField
              id="email"
              label="Email"
              value={email}
              setValue={setEmail}
              isRequired={true}
              placeholder="Enter your email address"
            />
            <PhoneNumberField
              phoneNumber={phone}
              setPhoneNumber={setPhone}
              country={country}
              setCountry={setCountry}
            />
          </Box>
        </Stack>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <TermsAgreementCard
          onClick={() =>
            handleClick({
              firstName,
              lastName,
              email,
              phone: formatPhoneNumber(phone, country),
            })
          }
          packageValidationOutput={packageValidationOutput}
          isLoading={isLoading}
          disabled={isDisabled()}
        />
      </Box>
    </Stack>
  );
};

export default GuestModeForm;
