import React, { useContext, useState, useMemo, useEffect } from 'react';

import { MembershipPurchaseWrapper } from './style';

import { useSearchParams } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';

import usePackage from '../../../hooks/usePackage';

import CheckoutForm from '../../../components/Checkout/CheckoutForm';
import MembershipDetails from '../../../components/discount/membership/MembershipDetails';
import { CustomerInformation } from '../../../hooks/useCart';
import useTitle from '../../../hooks/useTitle';
import { Box } from '@mui/material';
import PricingCard from '../../../components/shared/PricingCard';
import { Membership } from '../../../hooks/useCompany';
import { useAuth } from '../../../context/AuthContext';
import useUser from '../../../hooks/useUser';
  interface props {
  title?: string;
}
const MembershipPurchase: React.FC<props> = ({ title }) => {
  useTitle(title);
  const {user, setUser} = useAuth();
  const {getUser} = useUser();
  const [searchParams] = useSearchParams();
  const membershipId = searchParams.get('membershipId');
  const { purchasePackage } = usePackage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { company, servicesMap } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        const currentUser = await getUser(user.id!);
        setUser(currentUser);
      }
    };
    fetchUser();
  }, []);

  const membership = useMemo(() => {
    if (!company?.vouchers || !membershipId) return null;
    return company.vouchers.find(
      (voucher): voucher is Membership => 
        voucher.type === 'membership' && voucher.id === membershipId
    );
  }, [company?.vouchers, membershipId]);

  const service = useMemo(() => {
    if (!membership?.serviceId || !servicesMap) return null;
    return servicesMap.get(membership.serviceId);
  }, [membership, servicesMap]);

  const handleClick = async (customerInformation?: CustomerInformation) => {
    try {
      setIsLoading(true);
      const response = await purchasePackage(company?.id!, membershipId!, customerInformation);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const isUserAlreadyAMember = !!user &&user?.membershipProviderIds?.includes(membership?.providerId!);

  const registrationFees = useMemo(() => {
    if(!membership?.registrationFees || membership?.registrationFees === 0) return undefined;
    if(membership?.registrationFees && isUserAlreadyAMember) {
      return 0;
    }
    return membership?.registrationFees;
  }, [membership?.registrationFees, isUserAlreadyAMember]);

  if (!membership || !service) {
    return null; // or some error state
  }



  console.log("[TEST] Registration Fees", registrationFees);
  console.log("[TEST] Is User Already A Member", isUserAlreadyAMember);
  console.log("[TEST] Membership", membership);
 

  return (
    <MembershipPurchaseWrapper>
      <Box className="membership-details-container">
        <MembershipDetails
          membershipServiceName={service.name}
          membershipPrice={membership.price}
          membershipDuration={membership.days}
          membershipRegistrationFees={user ? registrationFees : undefined}
          membershipMaxUsage={membership.maxUsage}
          id={membership.id}
          title={membership.name}
        />
      </Box>

      <PricingCard
        subTotal={membership.price + (registrationFees || 0)}
        total={membership.price + (registrationFees || 0)}
        discount={0}
        vat={0}
      />

      <CheckoutForm
        handleClick={handleClick}
        isLoading={isLoading}
        totalPrice={membership.price + (registrationFees || 0)}
        hasServiceFees={false}
        forcingAuthentication={true}
      />
    </MembershipPurchaseWrapper>
  );
};

export default MembershipPurchase;
