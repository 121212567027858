import { Box, Typography, Button, Stack, Paper, Container } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Register from '../../pages/register';
import { CheckoutFormWrapper } from './style';
import { RegistrationStep } from '../../Utils/constants';
import { isValidUser } from '../../Utils/validate';
import TermsAgreementCard from './TermsAgreementCard';
import { ValidationOutput } from '../../hooks/usePackage';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import GuestModeForm from './GuestModeForm';

interface IProps {
  handleClick: any;
  isLoading: boolean;
  packageValidationOutput?: ValidationOutput;
  totalPrice?: number;
  hasServiceFees?: boolean;
  forcingAuthentication?: boolean;
}

const CheckoutForm: React.FC<IProps> = ({
  handleClick,
  isLoading,
  packageValidationOutput,
  totalPrice,
  hasServiceFees,
  forcingAuthentication,
}) => {
  const { user } = useAuth();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const [isGuestMode, setIsGuestMode] = useState(true);

  if (isValidUser(user)) {
    return (
      <CheckoutFormWrapper data-testid="checkout-form-wrapper">
        <Box sx={{ width: '100%' }}>
          <TermsAgreementCard
            onClick={handleClick}
            isLoading={isLoading}
            packageValidationOutput={packageValidationOutput}
            totalPrice={totalPrice}
            hasServiceFees={hasServiceFees}
          />
        </Box>
      </CheckoutFormWrapper>
    );
  }

  if (!company?.hasAuthentication) {
    return (
      <CheckoutFormWrapper data-testid="checkout-form-wrapper">
        <GuestModeForm
          handleClick={handleClick}
          isLoading={isLoading}
          packageValidationOutput={packageValidationOutput}
        />
      </CheckoutFormWrapper>
    );
  }


  if(company?.hasAuthentication && (!company?.hasGuestMode || forcingAuthentication)) {
    return (
      <CheckoutFormWrapper data-testid="checkout-form-wrapper">
        <Typography sx={{ fontWeight: 600}} align="center" gutterBottom>
          Please sign in to checkout
        </Typography>
        <Register registrationStep={RegistrationStep.Login} redirectToCalendar={false} />
      </CheckoutFormWrapper>
    );
  }



  return (
    <CheckoutFormWrapper data-testid="checkout-form-wrapper">
      <Container sx={{ py: 4 }}>
        <Paper 
          elevation={2}
          sx={{
            borderRadius: 3,
            p: { xs: 2, sm: 2 },
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={4}>
            <Box>
              <Typography 
                variant="h5" 
                component="h1" 
                align="center" 
                gutterBottom
                sx={{ 
                  fontWeight: 600,
                  mb: 2
                }}
              >
                Checkout
              </Typography>
              
              {company?.hasGuestMode && company?.hasAuthentication && (
                <Box sx={{ 
                  bgcolor: 'background.default', 
                  p: 2, 
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider'
                }}>
                  <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 2 }}>
                    {isGuestMode 
                      ? 'Already have an account? Sign in for faster checkout'
                      : 'Don\'t want to create an account? Continue as guest'}
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setIsGuestMode(!isGuestMode)}
                    sx={{ 
                      borderRadius: 2,
                      py: 1,
                      textTransform: 'none',
                      fontSize: '1rem',
                      fontWeight: 500,
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none'
                      }
                    }}
                  >
                    {isGuestMode ? 'Sign in to your account' : 'Continue as guest'}
                  </Button>
                </Box>
              )}
            </Box>
      
              {isGuestMode ? (
                <GuestModeForm
                  handleClick={handleClick}
                  isLoading={isLoading}
                  packageValidationOutput={packageValidationOutput}
                />
              ) : (
                <Register registrationStep={RegistrationStep.Login} redirectToCalendar={false} />
              )}
            
          </Stack>
        </Paper>
      </Container>
    </CheckoutFormWrapper>
  );
};

export default CheckoutForm;
