import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { ModernCardWrapper } from './style';
import { formatStringTime, formatPriceValue } from '../../../../../Utils/format';
import { IBookingCardProps } from '../../interfaces';
import { AddonPurchase } from '../../../../../hooks/useCart';
import dayjs from 'dayjs';
import { useBookingCard } from '../../hooks/useBookingCard';

const BookingCardTheme2: React.FC<IBookingCardProps> = ({
  booking,
  onCancelBookingHandler,
  showCancelOption = false,
  showRemoveFromCartOption = false,
}) => {
  const { handleRemoveFromCart, handleCancel } = useBookingCard(booking, onCancelBookingHandler);
  const formattedDate = dayjs(booking.date, 'YYYYMMDD').format('ddd MMM D, YYYY');

  const totalPrice = React.useMemo(() => {
    const addonsTotal =
      booking.addonsPurchases?.reduce(
        (sum: number, addon: AddonPurchase) => sum + addon.paidAmount,
        0
      ) || 0;
    return booking.price + addonsTotal;
  }, [booking.price, booking.addonsPurchases]);

  console.log('[Test] booking', booking);
  return (
    <ModernCardWrapper>
      <Box className="main-content">
        <Box className="header-section">
          <Box className="service-info">
            <StarIcon sx={{ width: 24, height: 24, color: 'rgba(36, 32, 57, 1)' }} />
            <Typography className="service-name">
              {booking.service.serviceName}
              {booking.quantity && booking.quantity > 1 && (
                <Box component="span" className="quantity-badge">
                  x{booking.quantity}
                </Box>
              )}
            </Typography>
            {booking.resource && (
              <Typography className="resource-name">{booking.resource.resourceName}</Typography>
            )}
          </Box>
        </Box>

        <Box className="details-section">
          <Box className="detail-row date-row">
            <CalendarTodayOutlinedIcon
              sx={{ width: 16, height: 16, color: 'rgba(36, 32, 57, 1)' }}
            />
            <Typography className="date-text">{formattedDate}</Typography>
          </Box>

          <Box className="detail-row time-row">
            <Box className="time-info">
              <AccessTimeOutlinedIcon
                sx={{ width: 16, height: 16, color: 'rgba(36, 32, 57, 1)' }}
              />
              <Box className="time-content">
                <Typography className="time-text">{formatStringTime(booking.startTime)}</Typography>
                {booking.serviceDuration?.durationTime && (
                  <>
                    <Box className="time-divider" />
                    <Typography className="duration-text">
                      {booking.serviceDuration.durationTime} min
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            {booking.addonsPurchases && booking.addonsPurchases.length > 0 && (
              <Typography className="booking-price">{formatPriceValue(booking.price)}</Typography>
            )}
          </Box>
        </Box>

        {!!booking.addonsPurchases?.length && (
          <Box className="addons-section">
            <Typography className="addons-title">Add-ons</Typography>
            {booking.addonsPurchases
              .sort((a, b) => a.addon.name.localeCompare(b.addon.name))
              .map((addon: AddonPurchase) => (
                <Box key={addon.id} className="addon-item">
                  <Typography className="addon-name">
                    {addon.addon.name}
                    {addon.quantity > 1 && (
                      <Box component="span" className="addon-quantity">
                        x {addon.quantity}
                      </Box>
                    )}
                  </Typography>
                  <Typography className="addon-price">
                    {formatPriceValue(addon.paidAmount)}
                  </Typography>
                </Box>
              ))}
          </Box>
        )}

        <Box className="actions-section">
          <Typography className="price">{formatPriceValue(totalPrice)}</Typography>

          <Box className="action-buttons">
            {showCancelOption && (
              <IconButton
                onClick={handleCancel}
                size="small"
                className="cancel-button"
                data-testid="cancel-booking-button"
              >
                <CancelIcon />
              </IconButton>
            )}
            {showRemoveFromCartOption && (
              <IconButton
                onClick={handleRemoveFromCart}
                size="small"
                className="remove-button"
                data-testid="remove-from-cart-button"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </ModernCardWrapper>
  );
};

export default BookingCardTheme2;
