import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { CartBookingCardWrapper } from './style';
import DateCard from './DateCard';
import BookingCardRow from './BookingCardRow';
import { AddonPurchase } from '../../../../../hooks/useCart';
import { formatPriceValue } from '../../../../../Utils/format';
import { IBookingCardProps } from '../../interfaces';

const BookingCardTheme1: React.FC<IBookingCardProps> = ({
  booking,
  onCancelBookingHandler,
  // isCancelAddon = true,
  showCancelOption = false,
  showRemoveFromCartOption = false,
}) => {
  const month = booking.date.slice(4, 6);
  const day = booking.date.slice(6);

  return (
    <CartBookingCardWrapper>
      <Stack direction="row">
        <DateCard day={day} month={month} />

        <Box className="content-wrapper">
          <Stack className="title-wrapper">
            <Stack spacing={0.5}>
              <Typography variant="h6" className="title">
                {`${booking.service.serviceName} ${booking.quantity && booking.quantity > 1 ? `x${booking.quantity}` : ''}`}
              </Typography>

              {booking.resource && (
                <Typography variant="body2" color="text.secondary">
                  {booking.resource.resourceName}
                </Typography>
              )}
            </Stack>

            <BookingCardRow
              showRemoveFromCartOption={showRemoveFromCartOption}
              showCancelOption={showCancelOption}
              booking={booking}
              onCancelBookingHandler={onCancelBookingHandler}
            />
          </Stack>

          {!!booking.addonsPurchases?.length && (
            <Box className="addons-section">
              {booking.addonsPurchases
                .sort((a, b) => a.addon.name.localeCompare(b.addon.name))
                .map((addon: AddonPurchase) => (
                  <Box key={addon.id} className="addon-item">
                    <Typography variant="body2">
                      {addon.addon.name}
                      {addon.quantity > 1 && (
                        <Typography component="span" color="primary.main" ml={1}>
                          x{addon.quantity}
                        </Typography>
                      )}
                    </Typography>
                    <Typography variant="body2" color="primary.main">
                      {formatPriceValue(addon.paidAmount)}
                    </Typography>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Stack>
    </CartBookingCardWrapper>
  );
};

export default BookingCardTheme1;
